<template>
  <div class="roleMain">
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right" class="mB20">
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>项目管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="hengBox"></div> -->
    <div class="box center justify">
      <el-form :inline="true" class="box center" :model="ruleForm" ref="ruleForm">
        <el-form-item label="省份" prop="provinceId">
          <el-select @change="provinceChange" v-model="ruleForm.provinceId" placeholder="请选择">
            <el-option v-for="item in provinceList" :key="item.name" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item class="ml24" label="城市" prop="cityId">
          <el-select v-model="ruleForm.cityId" placeholder="请选择">
            <el-option v-for="item in cityList" :key="item.name" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入桥梁名称"></el-input>
        </el-form-item>
        <el-form-item prop="departmentName">
          <el-input v-model="ruleForm.departmentName" placeholder="请输入部门名称"></el-input>
        </el-form-item>
        <el-form-item prop="adminCompany">
          <el-button @click="handleCurrentChange(1)">查询</el-button>
          <el-button @click="resetData">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button @click="jumpAdd('', 'add')" :class="!myUpload ? 'disable_icon' : ''">新建项目</el-button>
    </div>
    <el-table class="mT30" :row-class-name="tableRowClassName" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
      <el-table-column align="center" prop="name" label="桥梁名称"></el-table-column>
      <el-table-column align="center" prop="provinceName" label="所属省份"></el-table-column>
      <el-table-column align="center" prop="cityName" label="所属城市"></el-table-column>
      <el-table-column align="center" prop="departmentName" label="所属单位"></el-table-column>
      <el-table-column align="center" width="240" prop="createTime" label="创建时间"></el-table-column>
      <el-table-column align="center" min-width="45" prop="id" label="操作">
        <template slot-scope="scope">
          <!-- <svg @click="jumpAdd(scope.row.id,'look')" class="icon fs24 operate-txt" aria-hidden="true">
            <use xlink:href="#iconchakan"></use>
          </svg>
          <svg @click="jumpAdd(scope.row.id,'edit')" class="icon fs22 operate-txt ml24" aria-hidden="true">
            <use xlink:href="#iconbianji"></use>
          </svg>
          <svg @click="deleteRole(scope.row.id)" class="icon fs22 operate-txt ml24" aria-hidden="true">
            <use xlink:href="#iconshanchu"></use>
          </svg> -->
          <span type="text" class="colorText mR20 pointer" size="small" @click="jumpAdd(scope.row.id, 'look')" :class="!myOption ? 'disable_icon' : ''">查看</span>
          <span type="text" class="colorText mR20 pointer" size="small" @click="jumpAdd(scope.row.id, 'edit')" :class="!myOption ? 'disable_icon' : ''">修改</span>

          <span type="text" class="colorText pointer" size="small" @click="deleteRole(scope.row.id)" :class="!myDel ? 'disable_icon' : ''">删除</span>
        </template>
      </el-table-column>
      <template slot="empty">
        <span class="iconfont iconzanwushuju-"></span>
        <span>暂无数据</span>
      </template>
    </el-table>
    <common-pagination class="pageMain" :pageSize="pageSize" :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
  </div>
</template>

<script>
import CommonPagination from "../../../components/Pagination";
export default {
  name: "roleList",
  components: {
    CommonPagination
  },
  data() {
    return {
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      loading: false,
      ruleForm: { provinceId: "", cityId: "", name: "", departmentName: "" },
      provinceList: [],
      cityList: []
    };
  },
  mounted() {
    this.getProvinceList();
    this.getTableList();
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    provinceChange(val) {
      this.ruleForm.provinceId = val;
      this.getCityList();
    },
    resetData() {
      this.$refs.ruleForm.resetFields();
      this.handleCurrentChange(1);
    },
    jumpAdd(id, type) {
      // if (type == "add") {
      //   if (!this.myUpload) {
      //     this.$message.error("您没有权限！");
      //     return;
      //   }
      // } else {
      //   if (!this.myOption) {
      //     this.$message.error("您没有权限！");
      //     return;
      //   }
      // }

      this.$router.push({
        name: "addPro",
        query: {
          id: id,
          type
        }
      });
    },
    deleteRole(id) {
      if (!this.myDel) {
        this.$message.error("您没有权限！");
        return;
      }
      this.$confirm(`确认删除？`, "删除", {
        dangerouslyUseHTMLString: true,
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      }).then(() => {
        this.httpDel(id);
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableList();
    },
    getTableList() {
      const params = this.ruleForm;
      this.$axios.get(`${this.baseURL}base/baseProject/city/likename/${this.currentPage}/${this.pageSize}`, { params }).then((res) => {
        res = res.data;
        let data = res.data;
        if (res.errCode == 200) {
          this.tableData = data.records;
          this.total = data.total;
        }
      });
    },
    httpDel(id) {
      this.$axios.put(`${this.baseURL}base/baseProject/del/${id}`).then((res) => {
        if (res.data.errCode == 200) {
          this.$message({
            message: "删除成功",
            type: "success",
            duration: 1000
          });
          this.handleCurrentChange(1);
        }
      });
    },
    // 获取省份
    getProvinceList() {
      this.$axios.get(`${this.baseURL}base/baseRegionalProvince/all`).then((res) => {
        res = res.data;
        if (res.errCode === "200") this.provinceList = res.data;
      });
    },
    getCityList() {
      this.$axios.get(`${this.baseURL}base/baseRegionalCity/${this.ruleForm.provinceId}`).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          this.cityList = res.data;
          this.ruleForm.cityId = res.data[0].id;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.pageMain {
  margin-top: 40px;
}

::v-deep .el-form-item {
  margin-bottom: 0;

  .el-input {
    width: 140px;
    height: 32px;
  }

  .el-select {
    .el-input {
      width: 140px;
    }
  }

  button.el-button {
    width: 90px;
  }
}

::v-deep .el-input__icon {
  line-height: 32px !important;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
</style>
